.hl {
    display: flex;
    margin: 24px 0;
    font-size: 170%;
    justify-content: center;
}
.hl svg {
    margin: 4px 8px 0 0
}

