.header-wrapper {
  width: 100%;
  background-image: linear-gradient(to top, rgb(53, 56, 62), rgb(56, 59, 67), rgb(58, 63, 73), rgb(61, 66, 78), rgb(64, 69, 84));
  z-index: 999
}
.header {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  height: 52px;
}