.wrapper-loading {
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
.wrapper-loading span {
  margin-top: 20px;
  color: #bbb
}
.loading {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: -200px;
}
.loading img {
  position: absolute;
  top: 50%;
  left: 50%;
  animation-timing-function: linear
}
.loading img:first-child {
  animation: fade 5s;
  animation-iteration-count: infinite;
}
.loading img:nth-child(2) {
  animation: fade-resize 15s;
  animation-iteration-count: infinite;
}
@keyframes fade {
  0% {opacity: .5} 
  50% {opacity: 1}
  100% {opacity: .5}
}
@keyframes fade-resize {
  0% {opacity: .5; transform: scale(.9);}
  50% {opacity: 1; transform: scale(1);}
  100% {opacity: .5; transform: scale(.9);}
}