.drope-zone-image {
  position: relative;
  height: 0;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  outline: none;
  background-color: rgba(200,200,200,.3);
  cursor: pointer;
}
.drope-zone-image:hover {
  background-color: rgba(200,200,200,.6);
}
.drope-zone-image input {
  outline: none;
}
.image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  object-fit: cover;
}
.img:hover {
  cursor: pointer;
  opacity: .1;
}
.img-back {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -18px;
  z-index: 2;
  opacity: 1;
  color: #fff;
}
.img-back svg {
  width: 36px;
  height: 36px;
}
.img-wrapper {
  position: relative;
}
.delete-img {
  position: absolute;
  top: 0;
  right: 0;
}
.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50% 0 0 -50%;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: rgba(255,255,255,1);
}
.uploadProgress {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}