.small-btn {
  display: flex;
  justify-content: flex-start;
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
  text-transform: none;
  opacity: .5;
}
.small-btn svg {
    margin: 4px 8px 0 0
}