.dashboard-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card {
  width: 33%;

}
@media screen and (max-width: 600px){
  .card {
    width: 100%;
  }
  
}