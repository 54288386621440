.error-wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  min-height: 100vh;
}
.icon {
  height: 52px;
  width: 52px;
  color: #eee;
}
.text {
  color: #ccc
}
